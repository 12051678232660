<template>
  <div class="app-container">
    <h2>规则列表</h2>
    <ul class="rule-list">
      <li
        v-for="(item, index) in contList"
        :key="index"
        @click="openDetail(item.path)"
      >
        <span>{{ item.title }}</span>
        <span class="time">{{ item.time }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import rule from '@/utils/platformRule';
import { projectConfigDetail } from '@/utils/projectConfig';

export default {
  name: 'rule-list',
  data() {
    return {
      contList: [],
    };
  },
  created() {
    let { code } = projectConfigDetail;
    this.contList = rule[code];
  },
  methods: {
    openDetail(path) {
      window.open(path);
    },
  },
};
</script>
<style lang='scss' scoped>
.app-container {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
  h2 {
    font-size: 24px;
    margin-bottom: 30px;
    font-weight: bolder;
  }
  ul {
    padding-left: 24px;
  }
  ul li{
    list-style-type: disc;
    line-height: 40px;
    font-size: 16px;
    margin-bottom: 10px;
    padding: 0 10px;
    box-sizing: border-box;
    width: 100%;
    background: #f8f9fa;
    cursor: pointer;
    .time {
      float: right;
      color: #999;
    }
  }
}
</style>
