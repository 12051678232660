var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container" }, [
    _c("h2", [_vm._v("规则列表")]),
    _c(
      "ul",
      { staticClass: "rule-list" },
      _vm._l(_vm.contList, function (item, index) {
        return _c(
          "li",
          {
            key: index,
            on: {
              click: function ($event) {
                return _vm.openDetail(item.path)
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(item.title))]),
            _c("span", { staticClass: "time" }, [_vm._v(_vm._s(item.time))]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }