export default {
  3300: [{
    title: '《网上农博处罚规则》',
    time: '2020-04-09',
    path: 'https://wsnbh.oss-cn-hangzhou.aliyuncs.com/pdf/2020/%E7%BD%91%E4%B8%8A%E5%86%9C%E5%8D%9A%E5%A4%84%E7%BD%9A%E8%A7%84%E5%88%990402.pdf',
  }, {
    title: '《网上农博平台合作协议》',
    time: '2020-04-28',
    path: 'https://wsnbh.oss-cn-hangzhou.aliyuncs.com/h5/nbh_gys_admin/cdn/%E7%BD%91%E4%B8%8A%E5%86%9C%E5%8D%9A%E5%B9%B3%E5%8F%B0%E5%90%88%E4%BD%9C%E5%8D%8F%E8%AE%AE.pdf',
  }, {
    title: '《网上农博商家管理规则》',
    time: '2020-04-28',
    path: 'https://wsnbh.oss-cn-hangzhou.aliyuncs.com/pdf/2020/%E7%BD%91%E4%B8%8A%E5%86%9C%E5%8D%9A%E5%95%86%E5%AE%B6%E7%AE%A1%E7%90%86%E8%A7%84%E5%88%99%E7%AC%AC%E4%BA%8C%E7%89%88.pdf',
  }, {
    title: '《网上农博商品描述及质量抽检规则》',
    time: '2020-04-09',
    path: 'https://wsnbh.oss-cn-hangzhou.aliyuncs.com/pdf/2020/%E7%BD%91%E4%B8%8A%E5%86%9C%E5%8D%9A%E5%95%86%E5%93%81%E6%8F%8F%E8%BF%B0%E5%8F%8A%E8%B4%A8%E9%87%8F%E6%8A%BD%E6%A3%80%E8%A7%84%E5%88%990403.pdf',
  }, {
    title: '《网上农博仲裁流程与标准》',
    time: '2020-04-09',
    path: 'https://wsnbh.oss-cn-hangzhou.aliyuncs.com/pdf/2020/%E7%BD%91%E4%B8%8A%E5%86%9C%E5%8D%9A%E4%BB%B2%E8%A3%81%E6%B5%81%E7%A8%8B%E4%B8%8E%E6%A0%87%E5%87%860403.pdf',
  }, {
    title: '《网上农博平台活动营销规则（总）》',
    time: '2020-04-28',
    path: 'https://wsnbh.oss-cn-hangzhou.aliyuncs.com/pdf/2020/%E3%80%8A%E7%BD%91%E4%B8%8A%E5%86%9C%E5%8D%9A%E5%B9%B3%E5%8F%B0%E6%B4%BB%E5%8A%A8%E8%90%A5%E9%94%80%E8%A7%84%E5%88%99%EF%BC%88%E6%80%BB%EF%BC%89%E3%80%8B.pdf',
  }, {
    title: '《网上农博农产品准入准出规则》',
    time: '2020-04-28',
    path: 'https://wsnbh-img.hzanchu.com/pdf/20200616/%EF%BC%88%E6%96%B0%E5%A2%9E%EF%BC%89%E7%BD%91%E4%B8%8A%E5%86%9C%E5%8D%9A%E5%86%9C%E4%BA%A7%E5%93%81%E5%87%86%E5%85%A5%E5%87%86%E5%87%BA%E8%A7%84%E5%88%990616.pdf',
  }],
  3100: [{
    title: '《鱼米之乡平台合作协议》',
    time: '2021-08-31',
    path: 'https://wsnbh-img.hzanchu.com/test/51351931115896832.pdf',
  }, {
    title: '《鱼米之乡平台总则》',
    time: '2021-08-31',
    path: 'https://wsnbh-img.hzanchu.com/test/51352000846200832.pdf',
  }, {
    title: '《鱼米之乡平台商家准入准出规则》',
    time: '2021-08-31',
    path: 'https://wsnbh-img.hzanchu.com/test/51352067552411648.pdf',
  }, {
    title: '《鱼米之乡平台商品发布及信息管理规范》',
    time: '2021-08-31',
    path: 'https://wsnbh-img.hzanchu.com/test/51352121369526272.pdf',
  }, {
    title: '《鱼米之乡平台活动营销规则》',
    time: '2021-08-31',
    path: 'https://wsnbh-img.hzanchu.com/test/51352178034573312.pdf',
  }, {
    title: '《鱼米之乡平台发货管理规则》',
    time: '2021-08-31',
    path: 'https://wsnbh-img.hzanchu.com/test/51352231893630976.pdf',
  }, {
    title: '《鱼米之乡平台商家售后服务管理规则》',
    time: '2021-08-31',
    path: 'https://wsnbh-img.hzanchu.com/test/51352469194768384.pdf',
  }, {
    title: '《鱼米之乡商家违规管理规则》',
    time: '2021-08-31',
    path: 'https://wsnbh-img.hzanchu.com/test/51352711843643392.pdf',
  },
  {
    title: '《鱼米之乡平台大促活动商家奖励机制》',
    time: '2021-11-23',
    path: 'https://nb-img.hzanchu.com/test/81794469752569856.pdf',
  }],
  5100: [
    {
      title: '《川府农博平台合作协议》',
      time: '2023-07-28',
      path: 'https://wsnbh.oss-accelerate.aliyuncs.com/h5/nbh_gys_admin/pdf/%E3%80%8A%E5%B7%9D%E5%BA%9C%E5%86%9C%E5%8D%9A%E5%B9%B3%E5%8F%B0%E5%90%88%E4%BD%9C%E5%8D%8F%E8%AE%AE%E3%80%8B.pdf',
    },
    {
      title: '《川府农博仲裁流程与标准》',
      time: '2023-07-28',
      path: 'https://wsnbh.oss-accelerate.aliyuncs.com/h5/nbh_gys_admin/pdf/%E3%80%8A%E5%B7%9D%E5%BA%9C%E5%86%9C%E5%8D%9A%E4%BB%B2%E8%A3%81%E6%B5%81%E7%A8%8B%E4%B8%8E%E6%A0%87%E5%87%86%E3%80%8B.pdf',
    },
    {
      title: '《川府农博农产品准入准出规则》',
      time: '2023-07-28',
      path: 'https://wsnbh.oss-accelerate.aliyuncs.com/h5/nbh_gys_admin/pdf/%E3%80%8A%E5%B7%9D%E5%BA%9C%E5%86%9C%E5%8D%9A%E5%86%9C%E4%BA%A7%E5%93%81%E5%87%86%E5%85%A5%E5%87%86%E5%87%BA%E8%A7%84%E5%88%99%E3%80%8B.pdf',
    },
    {
      title: '《川府农博商品描述及质量抽检规则》',
      time: '2023-07-28',
      path: 'https://wsnbh.oss-accelerate.aliyuncs.com/h5/nbh_gys_admin/pdf/%E3%80%8A%E5%B7%9D%E5%BA%9C%E5%86%9C%E5%8D%9A%E5%95%86%E5%93%81%E6%8F%8F%E8%BF%B0%E5%8F%8A%E8%B4%A8%E9%87%8F%E6%8A%BD%E6%A3%80%E8%A7%84%E5%88%99%E3%80%8B.pdf',
    },
    {
      title: '《川府农博商家管理规则第二版》',
      time: '2023-07-28',
      path: 'https://wsnbh.oss-accelerate.aliyuncs.com/h5/nbh_gys_admin/pdf/%E3%80%8A%E5%B7%9D%E5%BA%9C%E5%86%9C%E5%8D%9A%E5%95%86%E5%AE%B6%E7%AE%A1%E7%90%86%E8%A7%84%E5%88%99%E7%AC%AC%E4%BA%8C%E7%89%88%E3%80%8B.pdf',
    },
    {
      title: '《川府农博处罚规则》',
      time: '2023-07-28',
      path: 'https://wsnbh.oss-accelerate.aliyuncs.com/h5/nbh_gys_admin/pdf/%E3%80%8A%E5%B7%9D%E5%BA%9C%E5%86%9C%E5%8D%9A%E5%A4%84%E7%BD%9A%E8%A7%84%E5%88%99%E3%80%8B.pdf',
    },
    {
      title: '《川府农博平台活动营销规则（总）》',
      time: '2023-07-28',
      path: 'https://wsnbh.oss-accelerate.aliyuncs.com/h5/nbh_gys_admin/pdf/%E3%80%8A%E5%B7%9D%E5%BA%9C%E5%86%9C%E5%8D%9A%E5%B9%B3%E5%8F%B0%E6%B4%BB%E5%8A%A8%E8%90%A5%E9%94%80%E8%A7%84%E5%88%99%EF%BC%88%E6%80%BB%EF%BC%89%E3%80%8B.pdf',
    },
    {
      title: '《川府农博直播平台绿色直播文明公约》',
      time: '2023-07-28',
      path: 'https://wsnbh.oss-accelerate.aliyuncs.com/h5/nbh_gys_admin/pdf/%E3%80%8A%E5%B7%9D%E5%BA%9C%E5%86%9C%E5%8D%9A%E7%9B%B4%E6%92%AD%E5%B9%B3%E5%8F%B0%E7%BB%BF%E8%89%B2%E7%9B%B4%E6%92%AD%E6%96%87%E6%98%8E%E5%85%AC%E7%BA%A6%E3%80%8B.pdf',
    },
  ],
};
